import { Card, Row, Col, Button, Space, Empty, Typography, Flex } from 'antd';
import { useEffect, useState } from 'react';
import { ShoppingCartOutlined, LeftOutlined } from '@ant-design/icons';
import deleteIcon from '../../assets/images/deleteIcon.png';
import { AiTwotoneDelete } from 'react-icons/ai';
import './CartStyle/firstSection.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import vector5 from '../../assets/images/Vector5.svg';
import {
	addProductToCart,
	deleteProductFromCart,
	getUserAddress,
	getUserCartList,
	updateProductFromCart,
} from '../../api/user';
import { updateCartItem } from '../../Redux/actionTypes';
import { useNavigate } from 'react-router-dom';
import { formatToRupees } from '../../utils/formatToRupees';
import { setAddresses } from '../../Redux/actionTypes/authAction';
const { Text } = Typography;

const CartDetail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [totalAmount, setTotalAmount] = useState(0);
	const cartItems = useSelector((state) => state.cart.cartItems);
	const [addressList, setAddressList] = useState([]);

	const incrementQuantity = (productDetails) => {
		const productDetailsAndCount = {
			product_id: productDetails.product_id,
			count: 1,
		};
		addProductToCart(productDetailsAndCount).then((response) => {
			getUserCartList().then((response) => {
				dispatch(updateCartItem(response));
			});
		});
	};

	const onCheckoutButtonClicked = () => {
		navigate('/checkout/address');
	};

	const deleteItemFromCart = async (productDetails) => {
		const productidObj = {
			id: productDetails.id,
		};
		await deleteProductFromCart(productidObj);
		getUserCartList().then((response) => {
			dispatch(updateCartItem(response));
		});
	};

	const decrementQuantity = async (productDetails) => {
		if (productDetails.count > 1) {
			const payload = {
				id: productDetails.id,
				count: productDetails.count - 1,
			};
			await updateProductFromCart(payload);
			getUserCartList().then((response) => {
				dispatch(updateCartItem(response));
			});
		}
	};

	const getTotalAmount = (cartItems) => {
		let totalAmount = 0;
		if (cartItems?.length) {
			cartItems.forEach((item) => {
				console.log(item.booking_type + item.bookingPrice);
				totalAmount +=
					item.booking_type == 'booking'
						? item.bookingPrice * item.count
						: (item.variant && item.variant != null
								? getVariantPrice(item)
								: item.sellingPrice) * item.count;
			});
		}
		setTotalAmount(totalAmount);
	};

	const getVariantPrice = (cartItem) => {
		if (cartItem && cartItem.variants && cartItem.variants.length) {
			let variant = cartItem.variant;
			let selectedVariant = cartItem.variants.filter(
				(item) => item.spec === variant
			);

			if (selectedVariant.length > 0) {
				return selectedVariant[0].price;
			}
		}
		return cartItem.sellingPrice;
	};

	const getAndStoreUserAddress = async () => {
		const addressList = await getUserAddress();
		setAddressList(addressList);
		dispatch(setAddresses(addressList));
	};

	useEffect(() => {
		getAndStoreUserAddress();
	}, []);

	useEffect(() => {
		getTotalAmount(cartItems);
	}, [cartItems]);

	return (
		<>
			<div
				onClick={() => navigate(-1)}
				style={{
					margin: '10px 20px',
					width: '140px',
					display: 'flex',
				}}
			>
				<Text className="back-btn-text" style={{ height: '40px' }}>
					{' '}
					<LeftOutlined className="product-detail-back-btn-icon" />
					Back
				</Text>
			</div>

			<section className="first">
				<Row
					type="flex"
					justify="center"
					align="middle"
					style={{ margin: '0 20px' }}
				>
					<Col span={12} style={{ alignItems: 'center' }}>
						<Text className="title">Your cart</Text>
					</Col>
					<Col
						span={12}
						style={{
							display: 'flex',
							justifyContent: 'right',
						}}
					>
						<Link to={'/products'}>
							<Text className="continue">Continue shopping</Text>
						</Link>
					</Col>
				</Row>
				{cartItems?.length ? (
					<Row
						type="flex"
						justify="start"
						align="middle"
						style={{ margin: '20px 25px 0 25px' }}
						span={10}
					>
						<Col span={14} style={{ alignItems: 'center' }}>
							<p className="bold-text">Product</p>
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'left',
							}}
						>
							<Text className="bold-text">Quantity</Text>
						</Col>
						<Col
							span={4}
							style={{
								display: 'flex',
								justifyContent: 'left',
							}}
						>
							<p className="bold-text">Total</p>
						</Col>
					</Row>
				) : (
					<></>
				)}
				{cartItems?.length ? (
					cartItems.map((item, index) => {
						let productDisplayImage = item.productImages?.split(',')?.[0];
						return (
							<Card
								key={index}
								className="card-alignment"
								style={{ backgroundColor: '#000000', marginTop: '10px' }}
							>
								<Row type="flex" justify="start" align="top">
									<Col span={14} pull={1}>
										<Row
											type="flex"
											justify="between"
											align="top"
											style={{ margin: '0 0 0 35px' }}
										>
											<Col xs={24} sm={8} style={{ paddingLeft: '30px' }}>
												<div
													className="product-image-wrapper"
													style={{ maxHeight: '208px', justifyContent: 'left' }}
												>
													<img
														className="product-image"
														src={productDisplayImage || vector5}
														alt="Product"
													/>
												</div>
											</Col>
											<Col xs={24} sm={16}>
												<div style={{ paddingLeft: '30px' }}>
													<Text className="product-title">{item.name}</Text>
													<div style={{ marginTop: '5px' }}>
														<Text className="product-price">
															Rs.{' '}
															{formatToRupees(
																(item.variant && item.variant != null
																	? getVariantPrice(item)
																	: item.sellingPrice)
															)}
														</Text>
													</div>
													<div className="product-details-container">
														<div>
															<Text className="product-details">
																Brand: {item?.brandName}
															</Text>
														</div>
														<div>
															<Text className="product-details">
																Selected variant: {item?.variant}
															</Text>
														</div>
														<div>
															<Text className="product-details">
																Order Type: {item.booking_type}
															</Text>
														</div>
														<div>
															<Text className="product-details">
																Color: {item.selectedColor}
															</Text>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</Col>
									<Col
										span={6}
										style={{
											display: 'flex',
											justifyContent: 'left',
										}}
									>
										<Space>
											<div className="quantity-container">
												<Flex>
													<div className="quantity-container-wrapper">
														<span>
															<Text
																onClick={() => decrementQuantity(item)}
																className="increment-decrement-btn"
															>
																-
															</Text>
														</span>
														<span className="item-count">{item.count}</span>
														<span>
															<Text
																onClick={() => incrementQuantity(item)}
																className="increment-decrement-btn"
															>
																+
															</Text>
														</span>
													</div>
												</Flex>
											</div>
											<AiTwotoneDelete
												className="delete-btn"
												style={{ fontSize: '1rem', color: '#fff' }}
												onClick={() => deleteItemFromCart(item)}
											/>
										</Space>
									</Col>
									<Col
										span={4}
										style={{
											display: 'flex',
											justifyContent: 'left',
										}}
									>
										<Space>
											{/* item.sellingPrice * item.count */}
											<span className="bold-text">
												Rs{' '}
												{item.booking_type == 'booking'
													? formatToRupees(item.bookingPrice * item.count)
													: formatToRupees(
															(item.variant && item.variant != null
																? getVariantPrice(item) * item.count
																: item.sellingPrice * item.count)
													  )}
											</span>
										</Space>
									</Col>
								</Row>
							</Card>
						);
					})
				) : (
					<Empty
						image={<ShoppingCartOutlined style={{ fontSize: 100 }} />}
						imageStyle={{
							height: 100,
						}}
						description={
							<Space direction="vertical" style={{ margin: '30px 0' }}>
								<span
									style={{
										fontFamily: 'AvenirNextLTPro',
										fontWeight: '600',
										fontSize: '22px',
									}}
								>
									Your cart is empty
								</span>
								{/* Add additional message or content here */}
							</Space>
						}
					/>
				)}
				{cartItems?.length ? (
					<Row type="flex" justify="center" className="pt">
						<Col span={13}>
							{/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                }}
                className="bottom-border "
              >
                <span className="bold-text">Discount Code</span>
                <span
                  className="bold-text"
                  style={{ color: #04b2a9, textDecoration: "underline" }}
                >
                  Apply
                </span>
              </div> */}
						</Col>
						<Col span={10}>
							<div className="checkout-container">
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<Text className="bold-text">
										Subtotal: Rs.{formatToRupees((totalAmount+(totalAmount*5/100)).toFixed(0))}
										<sub style={{ fontSize: '10px' }}>#incl. GST</sub>
									</Text>

								</div>
								<Text
									style={{
										color: '#848397',
										display: 'flex',
										justifyContent: 'flex-end',
										fontFamily: 'AvenirNextLTPro',
									}}
								>
									Taxes and shipping calculated at checkout
								</Text>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<Button
										className="gradient-btn"
										style={{ marginTop: '20px' }}
										onClick={() => onCheckoutButtonClicked()}
									>
										Check Out
									</Button>
								</div>
							</div>
						</Col>
					</Row>
				) : (
					<></>
				)}
			</section>
		</>
	);
};

export default CartDetail;
