import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from "antd"
import "./homeStyle/brandSection.css"
import Egh from "../../assets/brands/egh.jpg"
import Corrit from "../../assets/brands/corrit.png"
import Evolet from "../../assets/brands/evolet.png"
import Herald from "../../assets/brands/herald.png"
import Hop from "../../assets/brands/hop.png"
import Huse from "../../assets/brands/huse_segway_logo.jpeg"
import Motorad from "../../assets/brands/motorad.png"
import Power from "../../assets/brands/power.png"
import Truebikes from "../../assets/brands/truebikes_logo.jpeg"
import Virtus from "../../assets/brands/Virtus.png"
import Xorbit from "../../assets/brands/xorbit.jpeg"


const Brands = () => {

    const navigate = useNavigate();

    const brands = [
        { name: "Herald", url: "https://heraldebike.com/", src: Herald },
        { name: "Emotorad", url: "https://www.emotorad.com/", src: Motorad },
        { name: "Corrit Electric", url: "https://www.corritelectric.com/", src: Corrit },
        { name: "Huse", url: "https://huse.co.in/", src: Huse },
        { name: "Power Electric Vehicle", url: "https://powerelectricvehicles.co.in/", src: Power },
        { name: "Virtus Motors", url: "https://virtusmotors.in/", src: Virtus },
        { name: "Tru.Bike", url: "https://tru.bike/", src: Truebikes },
        { name: "Evoletindia", url: "https://www.evoletindia.com/", src: Evolet },
        { name: "Xorbit EV", url: "https://xorbitev.com/", src: Xorbit },
        { name: "Vegh", url: "https://www.thevegh.co.in/", src: Egh },
        { name: "Hop Electric", url: "https://hopelectric.in/", src: Hop }
    ];


    return (
        <Row className='brand-section-container' style={{ alignItems: 'center', justifyContent: "space-between", position: "relative" }}>
            <Col xs={24} sm={24} md={24} lg={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <div class="brand-image-container" >
                    {brands.map((brand, index) => (
                        <a href={""}>
                            <img src={brand.src} alt={brand.name} />
                        </a>

                    ))}
                </div>
            </Col>
        </Row >
    )
}

export default Brands
