import React, { useEffect, useRef, useState } from "react";
import vector5 from "../../assets/images/Vector5.svg";
import location from "../../assets/images/location.svg";
import {
  Row,
  Col,
  Typography,
  Rate,
  Button,
  Carousel,
  message,
  Spin,
  Flex,
  Space,
  Tabs,
  Divider,
  Image,
  Tooltip,
  Radio
} from "antd";
import "./productdetailStyle/productDetails.css";
import {
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { updateCartItem } from "../../Redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart, getUserCartList, userLogin } from "../../api/user";
import { getDealersByPincode } from "../../api/product";
import { getUserPincode } from "../../utils/googleMaps";
import { setUserPincode, setUserPincodeEdited } from "../../Redux/actionTypes/authAction";
import { Link, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { requestQuote } from "../../api/order";
import { initOTPless } from "../../utils/initOtpless";
import { Description } from "./ProductTabsData/Description";
import { formatToRupees } from "../../utils/formatToRupees";
import Reviews from "./ProductTabsData/Reviews";
import { OtherDetails } from "./ProductTabsData/OtherDetails";
const { Text } = Typography;

const contentStyle = {
  margin: 0,
  height: "100%",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
};

const ProductDetailsHeader = ({
  productData,
  isProductCompare,
  setIsProductCompare,
  setUserPinCode,
}) => {
  const {
    name,
    sellingPrice,
    productPrice,
    id,
    range_Overview,
    topSpeed_Overview,
    chargingTime,
    batteryCapacity,
    productImages,
    vendorId,
    motorPower,
    frontBrakeType,
    rearBrakeType,
    totalWeight,
    discount,
    reviews,
    category,
    brandName,
    color,
    driveModes,
    ratings,
    bookingPrice,
    variants,
    availableLocations
  } = productData;

  const dispatch = useDispatch();
  const authState = useSelector((state) => (state ? state.auth : []));
  const [isLocationSelector, setIsLocationSelector] = useState(false);
  const [active, setActive] = useState(false);
  const [quoteApiCalling, setQuoteApiCalling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [pinCode, setPinCode] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [availableDealers, setAvailableDealers] = useState(null);
  const [isUnavailable, setIsUnavailable] = useState(authState?.userCoords !== undefined && authState?.userPincode !== undefined
    ? !(productData?.availableLocations?.some(r => Object.values(authState.userPincode).includes(r)) || (productData?.availableLocations.includes("ALL_LOCATIONS")))
    : true);
  const [availableInCity, setAvailableInCity] = useState(!isUnavailable ? true : false);
  const [availableInDistOrState, setAvailableInDistOrState] = useState(!isUnavailable ? true : false);
  const [isDemoAvailable, setIsDemoAvailable] = useState(!isUnavailable ? true : false);
  const [isProductUnAvialable, setIsProductUnAvialable] = useState(isUnavailable ? true : false);
  const [isQuoteDisplay, setIsQuoteDisplay] = useState(false);
  const [userData, setUserData] = useState();
  const [imgIndex, setImgIndex] = useState(0);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [variantInfo, setVariantInfo] = useState(variants?.sort((variant1, varaint2) => variant1?.price - varaint2?.price)[0] || null);
  const [selectColor, setSelectColor] = useState(color.split(",")[0] || null)
  const onSelectVariant = (e) => {
    setVariantInfo(e);
  };

  const onSelectColor = (e) => {
    setSelectColor(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    getPincode();
    total_rating_count();
  }, []);

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.waNumber,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    setUserData(otplessUser);
    closeModal();
    window.location.reload();
  };

  const openModal = () => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
  };

  const closeModal = () => {
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "none";
    }
    const script = document.getElementById("otplessIdScript");
    if (script) {
      script.remove();
    }
  };

  const getQuotation = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      openModal();
    } else {
      setQuoteApiCalling(true);
      const user_data = JSON.parse(localStorage.getItem("user_details"));
      const user_contact_data = JSON.parse(
        localStorage.getItem("otpless_user_token")
      );

      const quoteData = {
        productId: id,
        productName: name,
        userName: user_data.full_name,
        userLocation: selectedLocation,
        status: "test",
        vendorId: vendorId,
        userEmail: user_contact_data.email,
      };
      try {
        requestQuote(quoteData).then((response) => {
          setQuoteApiCalling(false);
          message.success("We are sending you a qutoation");
        });
      } catch (error) {
        setQuoteApiCalling(false);
        message.error("An error occurred!");
      }
    }
  };

  const handleAddToCart = (itemDetails, isBuyNow, bookingType) => {
    if (variantInfo === null) {
      message.error("Please Select Variant");
      return;
    }
    if (!isProductUnAvialable) {
      setIsLoading(true);
      const otpless_user_token = JSON.parse(
        localStorage.getItem("otpless_user_token")
      );

      if (!otpless_user_token) {
        setIsLoading(false);
        openModal();
      } else {
        const productDetailsAndCount = {
          product_id: itemDetails.id,
          name: itemDetails.name,
          mrp: Number(variantInfo?.price),
          bookingPrice: itemDetails.bookingPrice,
          discount: itemDetails.discount,
          brandName: itemDetails.brandName,
          priceIncludesDelivery: itemDetails.priceIncludesDelivery,
          count: 1,
          booking_type: bookingType,
          variant: variantInfo == null ? "default" : variantInfo.spec,
          selectedColor: selectColor
        };
        try {
          updatePixelEvent(itemDetails);
          addProductToCart(productDetailsAndCount).then((response) => {
            getUserCartList().then((response) => {
              dispatch(updateCartItem(response));
              setIsLoading(false);
            });
          });
          if (isBuyNow) {
            navigate("/cart");
          }
        } catch (error) {
          setIsLoading(false);
          message.error("An error occurred!");
        }
      }
    }
  };

  const updatePixelEvent = (product) => {
    window.fbq('track', 'Cart', {
      content_name: product?.name,
      content_category: product?.brandName,
      content_ids: [product.id],
      content_type: product?.category,
      value: product?.productPrice,
      currency: 'INR'
    });
  };

  const bookDemo = (itemDetails) => {
    if (variantInfo === null) {
      message.error("Please Select Variant")
      return;
    }
    if (isDemoAvailable) {
      navigate(
        `/book-a-demo?productId=${itemDetails?.id}&vendorId=${itemDetails?.vendorId}&pincode=${selectedLocation}`
      );
    }
  };

  let imageUrls = productImages ? productImages?.split(",") : [vector5];

  const onChange = (currentSlide) => {
    //
  };

  const handleSearch = async (value) => {
    setIsLoading(true);
    setPinCode(value);
    setUserPinCode(value);
    setAvailableInCity(false);
    setAvailableInDistOrState(false);
    if (value.length < 6 || value.length > 6) {
      message.error("Please enter a valid 6-digit Pincode");
    } else {
      setIsLocationSelector(false);

      const body = {
        pincode: value,
        productId: id,
      };

      try {
        const { data } = await getDealersByPincode(body);
        dispatch(setUserPincodeEdited(value));
        if (data) {
          if (data?.availableDealers) {
            setAvailableInCity(true);
            setIsDemoAvailable(true);
            setIsProductUnAvialable(false);
          } else if (data?.otherDealers) {
            setIsQuoteDisplay(true);
            setAvailableInDistOrState(true);
            setIsProductUnAvialable(true);
            setAvailableDealers(data?.otherDealers.dealers);
            setIsDemoAvailable(false);
          }
          setIsLoading(false);
        }
      } catch (error) {
        message.error("Invalid pincode");
        setIsLoading(false);
      }
    }
  };
  const carouselRef = React.createRef();

  const handleNext = () => {
    if (imgIndex < imageUrls.length - 1) {
      setImgIndex(imgIndex + 1);
    }
  };

  const handlePrev = (index) => {
    setImgIndex(index);
  };

  const getPincode = async () => {
    console.log("%%%%%%%%%%userCoords%%%%%%%%%%" + (
      authState.userCoords
    ));
    if (
      authState.userCoords !== undefined
    ) {
      try {
        const pincode = await getUserPincode(authState.userCoords);
        console.log("%%%%%%%%%pincode%%%%%%%%%%%" + pincode);

        dispatch(setUserPincode(pincode));
        dispatch(setUserPincodeEdited(pincode));
        setSelectedLocation(pincode?.pincode);
      } catch (error) {
        console.error("Error fetching pincode:", error);
      }
    } else {
      setSelectedLocation(authState?.userPincode?.pincode);
    }
  };

  const total_rating_count = () => {
    if (reviews?.length > 0) {
      const rating_count = reviews.map((e) => e.rating);
      const total_rating = rating_count.reduce((a, b) => a + b, 0);
      const average_rating = total_rating / rating_count.length || 0;
      setAverageRating(average_rating);
    }
  };

  useEffect(() => {
    if (authState.userPincodeEdited) {
      setUserPinCode(authState.userPincodeEdited)
      setIsLocationSelector(false);
      setSelectedLocation(authState.userPincodeEdited.pinCode)
    }
  }, [authState.userPincodeEdited])

  useEffect(() => {
    const value = authState?.userCoords !== undefined && authState?.userPincode !== undefined
      ? !(productData?.availableLocations?.some(r => Object.values(authState.userPincode).includes(r)) || (productData?.availableLocations.includes("ALL_LOCATIONS")))
      : true;
    // const ppp = productData?.availableLocations?.some(r => Object.values(authState.userPincode).includes(r));
    setIsUnavailable(value);
    setIsProductUnAvialable(value ? true : false);
    setIsDemoAvailable(!value ? true : false);
    setAvailableInDistOrState(!value ? true : false);
    setAvailableInCity(!value ? true : false);
  }, [productData, authState.userCoords, authState.userPincodeEdited])

  const productDetailsItems = [
    {
      key: "1",
      label: "Description",
      children: <Description productData={productData} />,
    },
    {
      key: "2",
      label: "Other Details",
      children: (
        <OtherDetails
          name={name}
          category={category}
          brand={brandName}
          mrp={sellingPrice}
          color={color}
        />
      ),
    },
    {
      key: "3",
      label: "Reviews",
      children: <Reviews reviews={reviews} />,
    },
  ];

  return (
    <>
      <Flex gap={'small'} className="product-detail-back-container">
        <div className="product-detail-back-btn" onClick={() => navigate(-1)}>

          <Text className="back-btn-text"> <LeftOutlined className="product-detail-back-btn-icon" />Back</Text>
        </div>
      </Flex>
      <Row
        gutter={16}
        type="flex"
        justify="center"
        align="middle"


      >
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row gutter={16} justify="center" className="product-image-carousel" style={{ padding: "0px 20px", height: "100%", justifyContent: "start", alignItems: 'center' }} >
            {imageUrls?.length > 1 && <Col
              xs={6}
              sm={4}
              md={4}
              lg={4}
              className="img-view-container"
              style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", overflowY: "auto", maxWidth: "90px", overflowX: "hidden" }}

            >
              {


                imageUrls?.map((url, index) => {

                  const imgUrl = url;
                  const fileExtension = imgUrl.split('.').pop();
                  const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExtension);

                  const commonProps = {

                    src: imgUrl,
                    alt: "product-img",
                  };

                  return isVideo ? (
                    <video {...commonProps} style={{
                      width: "90%", objectFit: "contain", border: '1px solid #f2f2f2', padding: "10px", maxHeight: '60px', backgroundColor: "#f2f2f2",
                    }} key={index} className="shadow-md" autoPlay muted onMouseEnter={() => handlePrev(index)} onClick={() => { handlePrev(index) }} loop />
                  ) : (
                    <img {...commonProps} style={{
                      width: "80%", objectFit: "cover", border: '1px solid #f2f2f2', padding: "10px", maxHeight: '60px', minHeight: "60px", backgroundColor: "#f2f2f2"
                    }} key={index} className="shadow-md" onMouseEnter={() => handlePrev(index)} onClick={() => { handlePrev(index) }} />
                  );

                })
              }
            </Col>}
            <Col xs={18} md={20} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


              {(() => {
                const imgUrl = imageUrls[imgIndex] || vector5;
                const fileExtension = imgUrl.split('.').pop();
                const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExtension);

                const commonProps = {
                  className: "product-img",
                  src: imgUrl,
                  alt: "product-img",
                };

                return isVideo ? (
                  <video {...commonProps} autoPlay controls loop />
                ) : (
                  <img {...commonProps} />
                );
              })()}


            </Col>

          </Row>
        </Col>
        <Col
          xs={22}
          sm={22}
          md={24}
          lg={12}
          className="product-detail-container"
        >
          <Row >
            <Col xs={24} sm={24} md={24} lg={24}>
              <Flex gap="large" wrap="wrap">
                <div style={{ marginTop: "20px" }}><span className="heading-style"> {name}</span> <span style={{ fontSize: "18px", color: "#00ffff", margin: "0px 10px", fontWeight: "600" }}>{brandName}</span></div>
                {availableInDistOrState && (
                  <>
                    <InfoCircleOutlined
                      className="product-info-tooltip"
                      style={{ color: "#ffff" }}
                      onMouseOver={() => {
                        setActive(true);
                      }}
                      onMouseOut={() => {
                        setActive(false);
                      }}
                    />
                    {active && (
                      <div className="product-info-container">
                        <span>Product available in :</span>
                        {availableDealers?.map((e, index) => (
                          <p className="product-info-text" key={index}>
                            {e.city}
                          </p>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Flex>
            </Col>
            {isProductUnAvialable && (
              <Row gutter={20}>
                <Col span={24}>
                  <Flex gap="large" wrap="wrap">
                    <Text
                      type="danger"
                      style={{
                        paddingLeft: "10px",
                        fontFamily: "AvenirNextLTPro",
                      }}
                    >
                      Product is not available for your city
                    </Text>
                    {isQuoteDisplay &&
                      (quoteApiCalling ? (
                        <Spin />
                      ) : (
                        <div className="custom-quote-container">
                          <Text
                            onClick={getQuotation}
                            type="primary"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontFamily: "AvenirNextLTPro",
                              color: "#fff"
                            }}
                          >
                            Get custom quote for delivery
                          </Text>
                        </div>
                      ))}
                  </Flex>
                </Col>
              </Row>
            )}
            {availableInCity && (
              <Row gutter={20}>
                <Col span={24}>
                  <Text
                    type="success"
                    style={{
                      paddingLeft: "10px",
                      fontFamily: "AvenirNextLTPro",
                    }}
                  >
                    Product is available in your city
                  </Text>
                </Col>
              </Row>
            )}
            <Col xs={24} sm={24} md={24} lg={24}>
              <div style={{ marginTop: "10px" }}>
                <Row>
                  <Col>
                    <Text className="cost">
                      ₹ {formatToRupees((variantInfo == null ? (sellingPrice) : (Number(variantInfo?.price))).toFixed(0))}
                    </Text>
                  </Col>
                  <Col style={{ marginTop: "5px" }}>
                    <Text className="actual-cost" delete>
                      ₹ {formatToRupees(productPrice)}
                    </Text>
                  </Col>
                  <Col style={{ marginTop: "5px", marginLeft: "20px" }}>
                    <div className="discount-value">
                      <p style={{ marginTop: "17px" }}>{discount}% off</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Flex gap="large" wrap="wrap">
                <div
                  style={{
                    marginTop: "20px",
                    cursor: "pointer",
                    width: "200px",
                  }}
                >
                  <Flex
                    gap="large"
                    wrap="wrap"
                    onClick={() => setIsProductCompare(!isProductCompare)}
                  >
                    <Text type="primary" className="add-to-compare">
                      Compare EVs
                    </Text>

                  </Flex>
                </div>
                <div style={{ marginTop: "13px" }}>
                  <Rate
                    value={ratings}
                    style={{
                      fontSize: 22,
                      color: "gold",
                      marginRight: "16px",
                      paddingTop: "10px",
                      color: "#FF902D",
                    }}
                    disabled={true}
                  />
                  <span
                    style={{
                      fontSize: 18,
                      color: "gray",
                      fontFamily: "AvenirNextLTPro",
                    }}
                  >{`(${reviews?.length} Reviews)`}</span>
                </div>
              </Flex>
            </Col>
            <Divider />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ marginBottom: "20px" }}
            >
              <Row>
                {/* <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                    ₹ {formatToRupees((variantInfo == null ? (sellingPrice) : (Number(variantInfo?.price))).toFixed(0))}
                  </Text>
                  <div>
                    <Text className="product-specification-text">
                      Ex. Showroom
                    </Text>
                  </div>
                </Col> */}
                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                    ₹ {formatToRupees((variantInfo == null ? (sellingPrice * 0.05) :  (Number(variantInfo?.price) * 0.05)).toFixed(0))}
                  </Text>
                  <div>
                    <Text className="product-specification-text">GST 5%</Text>
                  </div>
                </Col>
                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                  ₹ {productData.priceIncludesDelivery?
                  "Free"
                  :
                  formatToRupees((variantInfo == null ? (sellingPrice * 0.05) 
                  : (Number(variantInfo?.price) * 0.05)).toFixed(0))}
                  </Text>
                  <div>
                    <Text className="product-specification-text">
                      Delivery Price
                    </Text>
                  </div>
                </Col>

                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                  ₹ {productData.priceIncludesDelivery?
                  formatToRupees((variantInfo == null ? (sellingPrice + (sellingPrice * 0.05)) 
                  : (Number(variantInfo?.price)) + (Number(variantInfo?.price) * 0.05)).toFixed(0))
                  :
                  formatToRupees((variantInfo == null ? (sellingPrice + (sellingPrice * 0.05)) 
                  : (Number(variantInfo?.price)) + (Number(variantInfo?.price) * 0.05) + (Number(variantInfo?.price) * 0.05)).toFixed(0))}
                  </Text>
                  <div>
                    <Text className="product-specification-text">
                      Total Price
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ marginBottom: "20px" }}
            >
              <Row>
                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                    {range_Overview} km
                  </Text>
                  <div>
                    <Text className="product-specification-text">Range</Text>
                  </div>
                </Col>
                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                    {topSpeed_Overview} km/h
                  </Text>
                  <div>
                    <Text className="product-specification-text">
                      Top Speed
                    </Text>
                  </div>
                </Col>
                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                    {chargingTime} hours
                  </Text>
                  <div>
                    <Text className="product-specification-text">
                      Charging time
                    </Text>
                  </div>
                </Col>
                <Col span={6} className="product-specification-container">
                  <Text className="product-specification-value">
                    {motorPower} kW
                  </Text>
                  <div>
                    <Text className="product-specification-text">Power</Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Row className="location-selector">
              <Col style={{ margin: "20px 0.3rem" }}>
                <Image src={location} preview={false} />
                <span className="location-header">{isUnavailable ? "Your Location:" : "Available all over India"}</span>
              </Col>
              {/* {!availableLocations?.includes("ALL_LOCATIONS") && <> */}
              {isLocationSelector ? (
                <Col span={6}>
                  <Space.Compact>
                    <Search
                      type="number"
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter pincode"
                      style={{ color: "#000" }}
                      className="pincode-input"
                      onSearch={handleSearch}
                      defaultValue={selectedLocation}
                      maxLength={6}
                      minLength={6}
                      onChange={(e) => {
                        setSelectedLocation(e.target.value);
                      }}
                    />
                  </Space.Compact>
                </Col>
              ) : (
                <Col>
                  <p className="selected-location"> {selectedLocation}</p>
                </Col>
              )}
              {/* {" "}</>} */}
              {isUnavailable && <Col
                onClick={() => {
                  setIsLocationSelector(!isLocationSelector);
                }}
                style={{ cursor: "pointer" }}
              >
                <EditOutlined
                  className={
                    isLocationSelector ? "edit-icon-active" : "edit-icon"
                  }
                  style={{ fontSize: "20px", color: "#ffff" }}
                />
              </Col>}
            </Row>
            {/* Adding Variants here in the block */}
            {color?.length > 0 ?
              <>
                <Row style={{ margin: "10px 0.3rem" }}>

                  <span className="location-header">Select Color:</span>
                </Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}

                  style={{ display: 'flex', gap: "10px", margin: "10px 20px", boxSizing: "border-box" }}
                >
                  {color.split(",")?.map((item, index) => (
                    <div key={index} onClick={() => setSelectColor(item)} style={{ width: "30px", height: "30px", borderRadius: "100%", transition: "all .4s", backgroundColor: item, border: `4px solid ${selectColor === item ? '#00ffff' : '#ddd'}`, boxShadow: selectColor === item ? `0px 0px 0px .2px ${item}` : 'none', boxSizing: "border-box" }}></div>
                  ))
                  }
                </Col>
              </>
              :
              <></>
            }
            {variants && variants.length > 0 ?
              <>
                <Row style={{ margin: "10px 0.3rem", boxSizing: 'border-box' }}>

                  <span className="location-header">{variants[0]?.variantType ? variants[0]?.variantType : "Select Variant"}</span>
                </Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  style={{ display: "flex", justifyContent: "start", flexWrap: 'wrap', gap: '10px' }}
                  className="variantContainer"
                >

                  {variants?.sort((variant1, varaint2) => variant1?.price - varaint2?.price).map((item, index) => (
                    <div key={index} style={{ margin: "5px 0", padding: "10px 0", border: `3px solid ${variantInfo.spec === item?.spec ? "#00ffff" : "#f2f2f2"}`, padding: "10px", borderRadius: "5px", backgroundColor: "#f2f2f2", cursor: "pointer", boxShadow: "0px 0px 3px #000000", transition: "all s" }} onClick={() => onSelectVariant(item)}>
                      {item.spec}
                    </div>
                  ))}

                </Col>
              </>
              :
              <></>
            }

            <Col xs={23} sm={23} md={24} lg={24}>
              <Row style={{ alignItems: "center", marginTop: "10px" }} gutter={20}>
                <Col xs={24} sm={8} style={{ display: 'flex', justifyContent: "start" }} className="addToBtn">

                  <Tooltip placement="bottom" title={!variantInfo ? "Please Select Variant" : isProductUnAvialable ? "Unavailable at your location" : ""} style={{ fontFamily: "AvenirNextLTPro" }}>
                    <Button
                      className="add-to-cart-btn"
                      onClick={() => handleAddToCart(productData, false, "purchase")}
                    >
                      {isLoading ? <Spin /> : "Add to cart"}
                    </Button>
                  </Tooltip>


                </Col>
                <Col xs={24} sm={8} style={{ display: 'flex', justifyContent: 'center' }} className="addToBtn">

                  <Tooltip placement="bottom" title={!variantInfo ? "Please Select Variant" : !isDemoAvailable ? "Unable to Demo at your location" : ""} style={{ fontFamily: "AvenirNextLTPro" }}>
                    <Button
                      className={"buy-now-btn"}
                      onClick={() => bookDemo(productData)}
                    >
                      {isLoading ? (
                        <Spin className="ant-spin-dot-item-custom" />
                      ) : (
                        "Book a demo"
                      )}
                    </Button>
                  </Tooltip>
                </Col>

                <Col xs={24} sm={8} className="addToBtn" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Tooltip placement="bottom" title={!variantInfo ? "Please Select Variant" : isProductUnAvialable ? "Unavailable at your location" : ""} style={{ fontFamily: "AvenirNextLTPro" }}>
                    {bookingPrice > 0 ? <Button
                      className="add-to-cart-btn"
                      onClick={() => handleAddToCart(productData, false, "booking")}
                    >
                      {isLoading ? <Spin /> : "Book @" + bookingPrice}
                    </Button>
                      :
                      <></>
                    }</Tooltip>

                </Col>
                {/* <Link
                  to={{
                    pathname: "/book-a-demo",
                    search: `?productId=${id}&vendorId=${vendorId}&pincode=${userPindCode || authState.userPincode?.pincode
                      }`,
                  }}
                >
                  <Button
                    disabled={isDemoAvailable}
                    className={"buy-now-btn"}
                  >
                    {isLoading ? <Spin /> : "Book Test Ride"}
                  </Button>
                </Link> */}
                {/* <Button
                  className="add-to-cart-btn"
                  style={{ marginLeft: "15px" }}
                  onClick={() => setIsProductCompare(!isProductCompare)}
                >
                  Compare <CheckSquareOutlined style={{ fontSize: "16px" }} />
                </Button> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="custom-tabs-container">
        <Tabs
          className="custom-tab-links"
          defaultActiveKey="1"
          items={productDetailsItems}
        />
      </div>
    </>
  );
};

export default ProductDetailsHeader;
