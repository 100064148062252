import "../ProductDetail/productdetailStyle/firstSection.css";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Col,
  Row,
  Divider,
  Button,
  message,
  Modal,
  List,
  Input,
  Empty,
  Tooltip, Tabs, Flex
} from "antd";
import vector5 from "../../assets/images/Vector5.svg";
import {
  LeftOutlined,
  CaretDownOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { getFiltered } from "../../api/product";
import { useDispatch, useSelector } from "react-redux";
import Product from "./Product";
import "./style/productCompare.css";
import { useLocation, useNavigate } from "react-router-dom";
import { addProductToCart, getUserCartList, userLogin } from "../../api/user";
import { updateCartItem } from "../../Redux/actionTypes";
import { setUserDetails } from "../../Redux/actionTypes/authAction";
import { initOTPless } from "../../utils/initOtpless";
import { debounce } from "../../utils/debounce";
import ProductCard from "./ProductCard";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const ProductCompare = ({
  setIsProductCompare,
  defaultProduct,
  userPinCode,
}) => {
  const [allProducts, setAllProducts] = useState([]);
  const [isOverviewOpen, setIsOverviewOpen] = useState(false);
  const [isPerformanceOpen, setIsPerformanceOpen] = useState(false);
  const [isDimensionOpen, setIsDimensionOpen] = useState(false);
  const [isBreakOverviewOpen, setIsBreakOverviewOpen] = useState(false);
  const [products, setProducts] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useSelector((state) => (state ? state.auth : []));
  const productState = useSelector((state) => (state ? state.product : []));
  const tabs = ["Overview", "Performance", "Dimension", "Brake Wheels & Suspensions"];


  const getProductBySearch = async (search) => {
    setIsProductsLoading(true);
    const filters = {
      sortingOrder: "",
      sortingCriteria: "",
      selectedColor: [],
      selectedCategory: [],
      priceRange: [0, productState.maxPrice * 1000],
      selectedRating: undefined,
      selectedBrand: [],
      selectedLocation: [],
      searchTerm: search.toLowerCase(),
    };
    try {
      const data = await getFiltered(
        {
          city: authState.userPincode?.city,
          district: authState.userPincode?.district,
          state: authState.userPincode?.state,
        },
        filters
      );
      if (data) {
        const filter_products = data.filteredProducts.filter((product) => product.id !== defaultProduct.id)
        setAllProducts(filter_products);
        setIsProductsLoading(false);
        // setIsModalOpen(true);
      }
    } catch (error) {
      setIsProductsLoading(false);
      message.error("Failed to fetch all products!");
    }
  };

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.waNumber,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    dispatch(setUserDetails(userInfo));
    closeModal();
    navigate();
  };

  const openModal = () => {
    if (authState?.userDetails) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
    // modalContainer !== null ? (modalContainer.style.display = "flex") : "";
  };

  const closeModal = () => {
    const modalContainer = document.getElementById("modalContainer");
    // if (e.target === modalContainer) {
    // 	modalContainer ? (modalContainer.style.display = 'none') : ''
    // }
    if (modalContainer) {
      modalContainer.style.display = "none";
    }
    const script = document.getElementById("otplessIdScript");
    if (script) {
      script.remove();
    }
    if (authState?.userDetails === null) {
      if (
        location.pathname === "/user/profile" ||
        location.pathname === "/user/orders" ||
        location.pathname === "/user/address"
      ) {
        navigate("/");
      }
    }
    // script ? script.remove() : "";
  };

  const handleAddToCart = (itemDetails) => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      openModal();
    } else {
      const productDetailsAndCount = {
        product_id: itemDetails.id,
        count: 1,
      };
      try {
        addProductToCart(productDetailsAndCount).then((response) => {
          getUserCartList().then((response) => {
            dispatch(updateCartItem(response));
          });
        });
      } catch (error) {
        message.error("An error occurred!");
      }
    }
  };

  const removeProduct = (productId) => {
    setProducts((prevState) =>
      prevState.filter((product) => product.id !== productId)
    );
  };

  const handleAddProduct = () => {
    setIsModalOpen(false);
    setSearch("");
    // let productObj = {
    //   Name: selectedProduct.name,
    //   Price: selectedProduct.sellingPrice,
    //   "Brand Name": selectedProduct.brandName,
    //   "Battery Capacity": selectedProduct.batteryCapacity,
    //   Range: selectedProduct.range_Overview,
    //   "Top Speed": selectedProduct.topSpeed_Overview,
    //   "Charging Time": selectedProduct.chargingTime,
    //   productImage: selectedProduct.productImages.split(",")[0],
    //   id: products.length,
    //   Action: (
    //     <>
    //       <div>
    //         <Button
    //           onClick={() => handleAddToCart(selectedProduct)}
    //           className="third-view-all-btn"
    //         >
    //           Buy Now
    //         </Button>
    //       </div>
    //       <div style={{ marginTop: 10 }}>
    //         <Button
    //           href={`/book-a-demo?${selectedProduct?.id}&vendorId=${selectedProduct?.vendorId}`}
    //           className="book-demo-btn custom-button"
    //         >
    //           Book a Demo
    //         </Button>
    //       </div>
    //     </>
    //   ),
    // };
    selectedProduct["productImage"] = Array.isArray(
      selectedProduct?.productImages
    )
      ? selectedProduct?.productImages.split(",")[0]
      : selectedProduct?.productImages;
    setProducts([...products, selectedProduct]);
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    setSearch(e.target.value);
    getProductBySearch(search);
  };

  useEffect(() => {
    // let defaultProductObj = {
    //   Name: defaultProduct.name,
    //   Price: defaultProduct.sellingPrice,
    //   "Brand Name": defaultProduct?.brandName,
    //   "Battery Capacity": defaultProduct.batteryCapacity,
    //   Range: defaultProduct.range_Overview,
    //   "Top Speed": defaultProduct.topSpeed_Overview,
    //   "Charging Time": defaultProduct.chargingTime,
    //   productImage: defaultProduct.productImages.split(",")[0],
    //   Action: (
    //     <>
    //       <div>
    //         <Button
    //           onClick={() => handleAddToCart(defaultProduct)}
    //           className="third-view-all-btn"
    //         >
    //           Buy Now
    //         </Button>
    //       </div>
    //       <div style={{ marginTop: 10 }}>
    //         <Button
    //           href={`/book-a-demo?${defaultProduct?.id}&vendorId=${defaultProduct?.vendorId}`}
    //           className="book-demo-btn custom-button"
    //         >
    //           Book a Demo
    //         </Button>
    //       </div>
    //     </>
    //   ),
    // };
    defaultProduct["productImage"] = Array.isArray(
      defaultProduct?.productImages
    )
      ? defaultProduct?.productImages.split(",")[0]
      : defaultProduct?.productImages;
    setProducts([defaultProduct]);
    setIsOverviewOpen(true);
  }, []);

  useEffect(() => {
    getProductBySearch("");
  }, []);

  return (
    <div style={{ width: "100%", backgroundColor: "#1D1E18" }}>
      <Modal
        centered
        title={[
          <p style={{ fontFamily: "AvenirNextLTPro", color: "#fff" }}>
            Select a product to compare
          </p>
        ]}
        open={isModalOpen}
        width={"80%"}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button className="confirm-btn" onClick={() => handleAddProduct()}>
            Confirm
          </Button>,
          <Button className="back-btn" onClick={() => setIsModalOpen(false)}>
            Back
          </Button>,
        ]}
      >
        <Input
          placeholder="Search for a product"
          onChange={debounce(handleSearch, 1000)}
        />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflowX: "hidden",
                overflowY: "auto",
              }}
              className="prodct-list-to-compare"
            >
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 3,
                  xl: 5,
                  xxl: 5,
                }}
                loading={isProductsLoading}
                dataSource={allProducts}
                locale={{
                  emptyText: (
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                        marginTop: "50px",
                      }}
                      description={
                        <span style={{ fontFamily: "AvenirNextLTPro" }}>
                          No products available
                        </span>
                      }
                    />
                  ),
                }}
                renderItem={(product) => (
                  <List.Item>
                    <Product
                      selectedProduct={selectedProduct}
                      isCompare={true}
                      product={product}
                      setSelectedProduct={setSelectedProduct}
                    />
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </Modal>
      <Flex gap={'small'} className="product-detail-back-container">
        <div className="product-detail-back-btn" onClick={() => setIsProductCompare(false)}>

          <Text className="back-btn-text"> <LeftOutlined className="product-detail-back-btn-icon" />Back</Text>
        </div>
      </Flex>
      <Row>

        <Col xs={22} sm={22} flex="auto">
          <Title level={2} className="compare-table-header">
            Compare for easy selection
          </Title>
        </Col>
      </Row>
      <Divider style={{ margin: "16px 0 0 0" }} />
      <div className="table-wrapper">
        <div className="table-container">
          <table className="tg">
            <tbody>
              <tr>
                <td className="table-data-label table-height"></td>
                {products?.map((product, index) => (
                  <td key={product?.name} className="tg-0lax image-cell" >
                    <div className="image-wrapper" style={{ position: "relative", display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                      <img
                        className="product-compare-image"
                        src={product?.productImage?.split(',')[0] || vector5}
                        style={{ height: '250px' }}
                        alt="product"
                      />
                      <div>
                        <p style={{ fontSize: "1.2rem", fontWeight: "600" }}>{product.name?.length > 10 ? product.name.slice(0, 15) + "..." : product.name}</p>
                        <p style={{ color: "#00ffff", fontSize: "14px", fontWeight: "600" }}>{product?.brandName}</p>
                        <p style={{ color: "#00ffff", fontSize: "14px", fontWeight: "600" }}>Rs.{' '}{product?.productPrice}</p>
                      </div>
                      <CloseOutlined onClick={() => removeProduct(product?.id)} className="close-icons-mobile" />
                    </div>
                  </td>
                ))}
                {products?.length !== 3 && (
                  <td className="tg-0lax">
                    {" "}
                    <div
                      className="select-product-container"
                      onClick={() => {
                        setIsModalOpen(!isModalOpen);
                        getProductBySearch("");
                        setSearch();
                      }}
                    >
                      <div className="select-product-inner-circle">
                        <span className="select-product-inner-plus">+</span>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
              <tr style={{ borderTop: "1px solid #f0f0f0" }}>
                <td
                  className="table-data-label"
                  onClick={() => setIsOverviewOpen(!isOverviewOpen)}
                >
                  Overview{" "}
                  <CaretDownOutlined
                    style={{ marginLeft: "5px" }}
                    rotate={isOverviewOpen ? 0 : -90}
                  />
                </td>
              </tr>
              {isOverviewOpen && (
                <>
                  <tr>
                    <td className="table-data-help-label">Range</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.range_Overview
                            ? `${product.range_Overview} km`
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Top Speed</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.topSpeed_Overview
                            ? `${product.topSpeed_Overview} km/h`
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Charging Time</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.chargingTime ? `${product.chargingTime} hours` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Safety</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.safety ? product.safety : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Transmission</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.transmission_Overview
                            ? product.transmission_Overview
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </>
              )}
              <tr style={{ borderTop: "1px solid #f0f0f0" }}>
                <td
                  className="table-data-label"
                  onClick={() => setIsPerformanceOpen(!isPerformanceOpen)}
                >
                  Performance{" "}
                  <CaretDownOutlined
                    style={{ marginLeft: "5px" }}
                    rotate={isPerformanceOpen ? 0 : -90}
                  />
                </td>
              </tr>
              {isPerformanceOpen && (
                <>
                  <tr>
                    <td className="table-data-help-label">Top Speed</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.topSpeed_Overview
                            ? `${product.topSpeed_Overview} km/h`
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Range</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.range_Overview
                            ? `${product.range_Overview} km`
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Driving Modes</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.driveModes ? product.driveModes : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Motor Type</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.motorType ? product.motorType : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Transmission</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.transmission_Overview
                            ? product.transmission_Overview
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Battery Type</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.batteryType ? product.batteryType : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Battery Capacity</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.batteryCapacity
                            ? product.batteryCapacity
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">
                      Charger Type and Capacity
                    </td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.chargerType ? product.chargerType : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Peak Motor Power</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.peakMotorPower
                            ? `${product.peakMotorPower} kW`
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Motor Power</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.motorPower ? `${product.motorPower} kW` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </>
              )}
              <tr style={{ borderTop: "1px solid #f0f0f0" }}>
                <td
                  className="table-data-label"
                  onClick={() => setIsDimensionOpen(!isDimensionOpen)}
                >
                  Dimension{" "}
                  <CaretDownOutlined
                    style={{ marginLeft: "5px" }}
                    rotate={isDimensionOpen ? 0 : -90}
                  />
                </td>
              </tr>
              {isDimensionOpen && (
                <>
                  <tr>
                    <td className="table-data-help-label">Total Weight</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.totalWeight ? `${product.totalWeight} kg` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Boot Space</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.bootSpace ? `${product.bootSpace} L` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Length</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.length ? `${product.length} mm` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Width</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.width ? `${product.width} mm` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Height</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.height ? `${product.height} mm` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Seat Length</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.seatLength ? `${product.seatLength} mm` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Ground Clearance</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.groundClearance
                            ? `${product.groundClearance} mm`
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Wheel Base</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.wheelbase ? `${product.wheelbase} mm` : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </>
              )}
              <tr
                style={{
                  borderTop: "1px solid #f0f0f0",
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                <td
                  className="table-data-label"
                  onClick={() => setIsBreakOverviewOpen(!isBreakOverviewOpen)}
                >
                  Brake Wheels & Suspensions{" "}
                  <CaretDownOutlined
                    style={{ marginLeft: "5px" }}
                    rotate={isBreakOverviewOpen ? 0 : -90}
                  />
                </td>
              </tr>
              {isBreakOverviewOpen && (
                <>
                  <tr>
                    <td className="table-data-help-label">Frame Type</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.frameType ? product.frameType : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Front Suspensions</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.frontSuspensions
                            ? product.frontSuspensions
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Rear Suspensions</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.rearSuspensions
                            ? product.rearSuspensions
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Front Brake type</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.frontBrakeType
                            ? product.frontBrakeType
                            : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Rear Brake type</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.rearBrakeType ? product.rearBrakeType : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">ABS / CBS</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.absCbs ? product.absCbs : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Wheel Type</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.wheelType ? product.wheelType : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Front Tyre</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.frontTyre ? product.frontTyre : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td className="table-data-help-label">Rear Tyre</td>
                    {products.map((product, index) => (
                      <React.Fragment key={`${product?.name}-${index}`}>
                        <td className="table-data-help-value">
                          {product.rearTyre ? product.rearTyre : "-"}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </>
              )}
              {/* {Object.keys(products?.length > 0 && products[0])?.map(
                (feature, index) => (
                  <>
                    {feature !== "productImage" && (
                      <tr key={feature}>
                        <td className="tg-0lax label">{feature}</td>
                        {products.map((product, index) => (
                          <React.Fragment
                            key={`${product?.name}-${feature}-${index}`}
                          >
                            <td className="tg-0lax">{product?.[feature]}</td>
                          </React.Fragment>
                        ))}
                        <td className="tg-0lax"></td>
                      </tr>
                    )}
                  </>
                )
              )} */}
              <tr>
                <td className="table-data-label table-height"></td>
                {products?.map((product, index) => {
                  const isUnavailable =
                    authState.userCoords !== undefined && authState.userPincode !== undefined
                      ? !product?.availableLocations?.some(r => Object.values(authState.userPincode).includes(r))
                      : (product?.isDemoble ? false : true);
                  return (
                    <>
                      <td key={product.Name}>
                        <div>
                          <Button
                            onClick={() => {
                              setIsProductCompare(false);
                              navigate(
                                `/product/${product?.id}`)
                            }
                            }
                            className="third-view-all-btn"
                          >
                            View
                          </Button>
                        </div>
                      </td>
                    </>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="table-wrapper-small">
        <div className="product-list-compare">
          {products?.map((product, index) => (

            <div className="product-card-compare" key={product?.name}>
              <div className="image-product">
                <img
                  className="product-image"
                  src={product?.productImage?.split(',')[0] || vector5}
                  alt="product"
                  loading="lazy"
                />

              </div>

              <CloseOutlined onClick={() => removeProduct(product?.id)} className="close-icons-mobile" />

              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between" }}>   <p className="product-name" onClick={() => navigate(`/product/${product.id}`)}> {product.name?.length > 8 ? product.name.slice(0, 8) + "..." : product.name}</p>
                <p className="brand-name" style={{ color: "#00ffff", whiteSpace: 'wrap', lineHeight: "14px" }}> {product.brandName?.length > 8 ? product.brandName.slice(0, 10) + "..." : product.brandName}</p>
                <p className="price" style={{ color: "#00ffff" }}>Price: {product.productPrice}</p>
              </div>
              <Button
                onClick={() => {
                  setIsProductCompare(false);
                  navigate(
                    `/product/${product?.id}`)
                }
                }
                className="third-view-all-btn"
              >
                View
              </Button>
            </div>

          ))}

          <div >
            {
              products?.length !== 3 && <div
                className="select-product-container"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  getProductBySearch("");
                  setSearch();
                }}
              >
                <div className="select-product-inner-circle">
                  <span className="select-product-inner-plus">+</span>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="product-list-compare">
          {products?.length && <div style={{ padding: "0px 0px ", margin: "10px" }}>
            <Tabs defaultActiveKey="1" style={{ fontSize: '16px', color: '#ffff' }}>
              {tabs.map((tab, index) => (
                <TabPane tab={tab} key={index + 1}>
                  <div >
                    <table style={{ color: "#ffff" }} >
                      <thead>
                        <tr>
                          <td className="table-data-help-label" style={{ color: "#000000" }} >feature</td>
                          {products?.map((item, index) => (
                            <td className="table-data-help-value" key={index} >
                              {item?.name || '-'}
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tab === "Overview" && <><tr >
                          <td className="table-data-help-label" >Range</td>
                          {products.map((product, index) => (

                            <td className="table-data-help-value" >
                              {product.range_Overview
                                ? `${product.range_Overview} km`
                                : "-"}
                            </td>

                          ))}
                        </tr>
                          <tr>
                            <td className="table-data-help-label">Top Speed</td>
                            {products.map((product, index) => (
                              <React.Fragment key={`${product?.name}-${index}`}>
                                <td className="table-data-help-value">
                                  {product.topSpeed_Overview
                                    ? `${product.topSpeed_Overview} km/h`
                                    : "-"}
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                          <tr>
                            <td className="table-data-help-label">Charging Time</td>
                            {products.map((product, index) => (
                              <React.Fragment key={`${product?.name}-${index}`}>
                                <td className="table-data-help-value">
                                  {product.chargingTime ? `${product.chargingTime} hours` : "-"}
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                          <tr>
                            <td className="table-data-help-label">Safety</td>
                            {products.map((product, index) => (
                              <React.Fragment key={`${product?.name}-${index}`}>
                                <td className="table-data-help-value">
                                  {product.safety ? product.safety : "-"}
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                          <tr>
                            <td className="table-data-help-label">Transmission</td>
                            {products.map((product, index) => (
                              <React.Fragment key={`${product?.name}-${index}`}>
                                <td className="table-data-help-value">
                                  {product.transmission_Overview
                                    ? product.transmission_Overview
                                    : "-"}
                                </td>
                              </React.Fragment>
                            ))}
                          </tr></>}

                        {tab === "Performance" && (
                          <>
                            <tr>
                              <td className="table-data-help-label">Top Speed</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.topSpeed_Overview
                                      ? `${product.topSpeed_Overview} km/h`
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Range</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.range_Overview
                                      ? `${product.range_Overview} km`
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Driving Modes</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.driveModes ? product.driveModes : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Motor Type</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.motorType ? product.motorType : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Transmission</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.transmission_Overview
                                      ? product.transmission_Overview
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Battery Type</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.batteryType ? product.batteryType : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Battery Capacity</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.batteryCapacity
                                      ? product.batteryCapacity
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">
                                Charger Type and Capacity
                              </td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.chargerType ? product.chargerType : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Peak Motor Power</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.peakMotorPower
                                      ? `${product.peakMotorPower} kW`
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Motor Power</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.motorPower ? `${product.motorPower} kW` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                          </>
                        )}

                        {tab === "Dimension" && (
                          <>
                            <tr>
                              <td className="table-data-help-label">Total Weight</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.totalWeight ? `${product.totalWeight} kg` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Boot Space</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.bootSpace ? `${product.bootSpace} L` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Length</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.length ? `${product.length} mm` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Width</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.width ? `${product.width} mm` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Height</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.height ? `${product.height} mm` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Seat Length</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.seatLength ? `${product.seatLength} mm` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Ground Clearance</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.groundClearance
                                      ? `${product.groundClearance} mm`
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Wheel Base</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.wheelbase ? `${product.wheelbase} mm` : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                          </>
                        )}

                        {tab === "Brake Wheels & Suspensions" && (
                          <>
                            <tr>
                              <td className="table-data-help-label">Frame Type</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.frameType ? product.frameType : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Front Suspensions</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.frontSuspensions
                                      ? product.frontSuspensions
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Rear Suspensions</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.rearSuspensions
                                      ? product.rearSuspensions
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Front Brake type</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.frontBrakeType
                                      ? product.frontBrakeType
                                      : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Rear Brake type</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.rearBrakeType ? product.rearBrakeType : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">ABS / CBS</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.absCbs ? product.absCbs : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Wheel Type</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.wheelType ? product.wheelType : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Front Tyre</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.frontTyre ? product.frontTyre : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                            <tr>
                              <td className="table-data-help-label">Rear Tyre</td>
                              {products.map((product, index) => (
                                <React.Fragment key={`${product?.name}-${index}`}>
                                  <td className="table-data-help-value">
                                    {product.rearTyre ? product.rearTyre : "-"}
                                  </td>
                                </React.Fragment>
                              ))}
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>

                </TabPane>
              ))}
            </Tabs>
          </div>}
        </div>
      </div>
    </div >
  );
};

export default ProductCompare;
