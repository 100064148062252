import FirstSection from '../Components/Vision/FirstSection';
import SecondSection from '../Components/Vision/SecondSection';
import ThirdSection from '../Components/Vision/ThirdSection';
import FourthSection from '../Components/Vision/FourthSection';
import FifthSection from '../Components/Vision/FifthSection';
import SixthSection from '../Components/Vision/SixthSection';
import SeventhSection from '../Components/Vision/SeventhSection';
import EigthSection from '../Components/Vision/EigthSection';

const Vision = () => {
	return (
		<div style={{ backgroundColor: '#000000' }}>
			<FirstSection />
			<SecondSection />
			<ThirdSection />
			<FourthSection />
			{/* <FifthSection />
			<SixthSection />
			<SeventhSection />
			<EigthSection /> */}
		</div>
	);
};

export default Vision;
